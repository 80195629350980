import { GetterTree } from 'vuex';
import { TblLieferantenState } from './types';
import { RootState } from '../../types';
import { LieferantenSearchData } from '@/shared/model/smallPayloadModels/lieferantenSearchData';

export const getters: GetterTree<TblLieferantenState, RootState> = {
  ['getLieferantens']: (state) => state.tblLieferantens,
  ['getLieferantensIsLoading']: (state) => state.tblLieferantens?.isLoading,
  ['getLieferantensTotal']: (state) => state.tblLieferantens?.total,
  ['getLieferantensSearchParams']: (state) => state.tblLieferantens?.searchParams,
  ['getLieferantenSearchData']: (state) => state.lieferantenSearchData,

  ['isLieferantenSearchDataEmpty']: (state) => {
    const searchData: LieferantenSearchData = state.lieferantenSearchData;
    let hasZrNummer = searchData.zrNumbersId?.length > 0;
    let hasStatusCompanies = searchData.statusCompanies?.length > 0;
    let isEmpty = !hasZrNummer && !hasStatusCompanies;
    return isEmpty;
  },
};
