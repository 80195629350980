import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import tblUpdateGPSRProdVOFile, {
  TblUpdateGPSRProdVOFile,
  Value,
  ZRNummerListResult,
} from '../model/tblUpdateGPSRProdVOFile';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

export interface BackendTblUpdateGPSRProdVOFile {
  getTblUpdateGPSRProdVOFiles: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  getAddGPSRProdVOFileCompleted(payload: any): AxiosPromise<any>;
  getCanselGPSRProdVOFileCompleted(payload: any): AxiosPromise<any>;
  getZRNummerList(searchParams: SearchParams, searchData?: any): AxiosPromise<ZRNummerListResult[]>;
  downloadGPSRProdVOFile(data: any): AxiosPromise<any>;
  downloadArtusFile(data: any): AxiosPromise<any>;
}

export const defaultBackendTblUpdateGPSRProdVOFile: BackendTblUpdateGPSRProdVOFile = {
  getTblUpdateGPSRProdVOFiles(searchParams: SearchParams, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.tblUpdateGPSRProdVOFileOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    if (searchParams.filter?.length != 0) {
      url = url.replace('contains(tolower(gpsr_filedate),', "contains(tolower(cast(gpsr_filedate, 'Edm.String')),");
      url = url.replace(
        'contains(tolower(gpsrFileDoneTimeCompleted),',
        "contains(tolower(cast(gpsrFileDoneTimeCompleted, 'Edm.String')),"
      );
    }

    return instance.put<Value>(url, searchData);
  },
  getZRNummerList(searchParams: SearchParams, searchData?: any): AxiosPromise<ZRNummerListResult[]> {
    return instance.put(`${URLS.tblUpdateGPSRProdVOFile}/GetZRNummerList`, searchData);
  },
  getAddGPSRProdVOFileCompleted(data: any): AxiosPromise<any> {
    return instance.put(`${URLS.tblUpdateGPSRProdVOFile}/AddGPSRProdVOFileCompleted`, data);
  },
  getCanselGPSRProdVOFileCompleted(data: any): AxiosPromise<any> {
    return instance.put(`${URLS.tblUpdateGPSRProdVOFile}/CanselGPSRProdVOFileCompleted`, data);
  },

  downloadGPSRProdVOFile(data: any): AxiosPromise<any> {
    return instance.put<any>(`${URLS.tblUpdateGPSRProdVOFile}/DownloadFile`, data, {
      responseType: 'blob',
    });
  },
  downloadArtusFile(data: any): AxiosPromise<any> {
    
    return instance.put<any>(`${URLS.tblUpdateGPSRProdVOFile}/DownloadArtusFile`, data, {
      responseType: 'blob',
    });
  },
};
