export enum ROUTES {
  //Used in `$router.push()`
  home = 'home',
  lists = 'lists',
  list = 'list',
  login = 'login',
  vmeSupplier = 'vmeSupplier',
  vmeKollege = 'vmeKollege',
  vmeBlock = 'vmeBlock',
  vmeUmsatz = 'vmeUmsatz',
  vmeUmsatzvergleich = 'vmeUmsatzvergleich',
  vmeUmsatzanzeige = 'vmeUmsatzanzeige',
  espotmarkt = 'espotmarkt',
  export = 'export',
  archive = 'archive',

  gspRoles = 'roles',
  gspRole = 'role',
  tasks = 'tasks',
  tasks_suppliers = 'tasks_suppliers',
  tasks_conditions = 'tasks_conditions',
  tasks_documents = 'tasks_documents',
  tasks_scaprix = 'tasks_scaprix',
  tasks_gpsr = 'tasks_gpsr',
  suppliers = 'suppliers',
  intern = 'intern',
  werbung = 'werbung',
  news = 'news',
  kollegenauslieferung = 'kollegenauslieferung',
  lieferanten = 'lieferanten',
  mitglieder = 'mitglieder',
  // ROUTES.gspRole
}
