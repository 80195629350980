import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import tblMitglieder, { TblMitglieder, Value } from '../model/tblMitglieder';
import {
  MitgliederFacetSearchData,
  MitgliederSearchData,
} from '@/shared/model/smallPayloadModels/mitgliederSearchData';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';
//import tblKollegenauslieferung, { TblKollegenauslieferung, Value1 } from '../model/tblKollegenauslieferung';
const searchableColumns: Map<string, string> = new Map([
  ['MemberzrNumber', 'zr_number'],
  ['GsName', 'gs_name'],
  ['ZipCircle', 'zip_circle'],
  ['DeliverAbroadCountry', 'deliver_abroad_country'],
  ['DeliverIslandName', 'deliver_island_name'],
  ['DeliveryColleagueContact', 'delivery_colleague_contact'],
  ['DeliveryColleagueEmail', 'delivery_colleague_email'],
  ['DeliveryColleaguePhone', 'delivery_colleague_phone'],
  ['DeliveryColleagueProductgroup', 'delivery_colleague_productgroup'],
  ['DeliveryColleagueCondition', 'delivery_colleague_condition'],
]);
const defaultOrSearchFields = [
  'name', 
  'suchname2', 
  'telefon', 
  'zrNummer',
  'plzGebiet',
  'plz',
  'strasse',
  'ort',
  'land',
  'webseite',
  'status', 
  'uStIdNrn',
  "cast(zrBeginn,'Edm.String')",
  "cast(zrEnde,'Edm.String')",
];

export interface BackendTblMitglieder {
  getMitglieder: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  getMitgliederFacetSearch: (facetSearch: MitgliederFacetSearchData) => AxiosPromise<Value>;
  getZrNumberList(): AxiosPromise<any>;
  getStatusCompaniesList(): AxiosPromise<any>;
  getMitgliederGebietPLZ: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
}

export const defaultBackendTblMitglieder: BackendTblMitglieder = {
  getZrNumberList(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.tblMitglieder}/GetZrNumberList`);
  },
  getStatusCompaniesList(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.tblMitglieder}/GetStatusCompaniesList`);
  },
  getMitgliederGebietPLZ(data: any): AxiosPromise<any> {
    let url = `${URLS.tblMitglieder}/GetListMitgliederGebietPLZ`;
    return instance.put<Value>(url, data);
  },
  getMitglieder(searchParams: any, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    if (searchParams.andClauseFieldsIds?.length > 0) {
      if (!searchParams.andClauseFieldsIds?.some((x: any) => x.chipField == 'all')) {
        searchParams.orClauseFieldsIds = [];
      } else {
        let allChipField = searchParams.andClauseFieldsIds?.find((x: any) => x.chipField == 'all');
        searchParams.filter = allChipField.searchWord;
        if (searchData.viewTable == 1) {
          searchParams.orClauseFieldsIds = defaultOrSearchFields;
        } else {
          searchParams.orClauseFieldsIds = [];
        }
        
      }
      let andClauseFieldsIds: Array<any> = [];
      
      if (searchData.viewTable == 1) {
        andClauseFieldsIds = searchParams.andClauseFieldsIds;
        andClauseFieldsIds.forEach((element: any, index: number) => {
          const chipField = searchableColumns.has(element.chipField)
            ? searchableColumns.get(element.chipField)
            : element.chipField;
          if (element.chipField == 'all') {
            // do nothing
          } else if (chipField != 'zip_circle') {
            odfb.contains(`tolower(${chipField})`, element.searchWord.toLowerCase());
          }
        });
      }
    }
 
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.tblMitgliederOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    if (url.toString().includes('contains(tolower(ZrEnde)')) {
      url = url.replaceAll('contains(tolower(ZrEnde),', "contains(tolower(cast(ZrEnde, 'Edm.String')),");
    }
    if (url.toString().includes('contains(tolower(ZrBeginn)')) {
      url = url.replaceAll('contains(tolower(ZrBeginn),', "contains(tolower(cast(ZrBeginn, 'Edm.String')),");
    }

    return instance.put<Value>(url, searchData);
  },

  getMitgliederFacetSearch(facetData: MitgliederFacetSearchData): AxiosPromise<any> {
    let url = `${URLS.tblMitglieder}/GetSearchResult`;
    return instance.put<Value>(url, facetData);
  },
};
