
import { MutationTree } from 'vuex';
import { TblLieferantenState } from './types';
import Vue from 'vue';
import tblLieferanten, { TblLieferanten, Value } from '@/shared/model/tblLieferanten';

export const mutations: MutationTree<TblLieferantenState> = {
  setTblLieferantens(state, payload: Value) {
    state.tblLieferantens.items = payload.value.map((x) => tblLieferanten.parse(x));
    state.tblLieferantens.total = payload['@odata.count'] || 0;
    state.tblLieferantens.isLoading = false;
  },
  setTblLieferantensTotal(state, payload: number) {
    state.tblLieferantens.total = payload;
  },
  setTblLieferantensIsLoading(state, payload: boolean) {
    state.tblLieferantens.isLoading = payload;
  }, 
  setLieferantenSearchData(state, payload: any) {
    state.lieferantenSearchData = payload;
  },
};
