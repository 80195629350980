export interface LieferantenFacetSearchData extends LieferantenSearchData {
    searchWord: string;
    chipDataString: string;
    // need all fields from SearchData for proper filtering and correct count numbers
}

export interface LieferantenSearchData {
    zrNumbersId: string[];
    statusCompanies: string[];   
    searchFacetFields: Array<any>;   
    chipDataString?: string;
}

function defaultData(): LieferantenSearchData {
    return {
        zrNumbersId: [],
        statusCompanies: [],  
        searchFacetFields: [],      
    };
}

export default {
    defaultData,
};
