import { GetterTree } from 'vuex';
import { TblUpdateScprixFilesState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<TblUpdateScprixFilesState, RootState> = {
  ['getTblUpdateScprixFiles']: (state) => state.tblUpdateScprixFiles,
  ['getTblUpdateScprixFilesIsLoading']: (state) => state.tblUpdateScprixFiles?.isLoading,
  ['getTblUpdateScprixFilesTotal']: (state) => state.tblUpdateScprixFiles?.total,
  ['getTblUpdateScprixFilesSearchParams']: (state) => state.tblUpdateScprixFiles?.searchParams,
  ['getDashboardDate']: (state) => state.dashboardDate,
  ['getDashboadrdScprixFilesStatus']: (state) => state.dashboardScprixFilesStatus,
};
