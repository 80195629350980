import { ActionTree } from 'vuex';
import { TblLieferantenState } from './types';
import { RootState } from '../../types';
import { defaultBackendTblLieferanten } from '@/shared/backend/tblLieferanten';
import {
  LieferantenFacetSearchData,
  LieferantenSearchData,
} from '@/shared/model/smallPayloadModels/lieferantenSearchData';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import tblLieferanten, { Value, TblLieferanten } from '@/shared/model/tblLieferanten';
import { defaultBackendTblKollegenauslieferung } from '@/shared/backend/tblKollegenauslieferung';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

export const actions: ActionTree<TblLieferantenState, RootState> = {
  getLieferanteng(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; lieferantenSearchData?: any }
  ) {
    commit('setTblLieferantensIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getLieferantenSearchData;
    let searchData = payload?.lieferantenSearchData ?? {};
    return defaultBackendTblLieferanten
      .getLieferanteng(searchParams, searchData)
      .then((response: AxiosResponse<Value>) => {
        commit('setTblLieferantens', response.data);
        commit('setTblLieferantensIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTblLieferantensIsLoading', false);
        throw e;
      });
  },
  getZrNumberList({ commit, dispatch }) {
    return defaultBackendTblLieferanten
      .getZrNumberList()
      .then((response: AxiosResponse<any>) => {
        return response.data;
      })
      .catch((e: any) => {
        throw e;
      });
  },
  getStatusCompaniesList({ commit, dispatch }) {
    return defaultBackendTblLieferanten
      .getStatusCompaniesList()
      .then((response: AxiosResponse<any>) => {
        return response.data;
      })
      .catch((e: any) => {
        throw e;
      });
  },
  getLieferantenFacetSearch({ commit, dispatch }, payload: LieferantenFacetSearchData) {
    return defaultBackendTblLieferanten
      .getLieferantenFacetSearch(payload)
      .then((response: AxiosResponse<any>) => {
        const result = response.data.$values ? response.data.$values : response.data;
        return result;
      })
      .catch((e: any) => {
        throw e;
      });
  },
  updateLieferantenSearchData({ commit, dispatch }, data: any) {
    commit('setLieferantenSearchData', data);
  },
};
