
import { ActionTree } from 'vuex';
import { TblUpdateGPSRProdVOFilesState } from './types';
import { RootState } from '../../types';
import { defaultBackendTblUpdateGPSRProdVOFile } from '@/shared/backend/tblUpdateGPSRProdVOFile';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import tblUpdateGPSRProdVOFile, { Value, TblUpdateGPSRProdVOFile, ZRNummerListResult } from '@/shared/model/tblUpdateGPSRProdVOFile';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import DownloadUtils from '@/shared/utils/DownloadUtils';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.tblUpdateGPSRProdVOFile');
export const actions: ActionTree<TblUpdateGPSRProdVOFilesState, RootState> = {
  getTblUpdateGPSRProdVOFiles({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any, gpsrprodVOSearchData?: any  }) {
    commit('setTblUpdateGPSRProdVOFilesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getTblUpdateGPSRProdVOFilesSearchParams;
    let gpsrSearchData = payload?.gpsrprodVOSearchData || {};
    return defaultBackendTblUpdateGPSRProdVOFile
      .getTblUpdateGPSRProdVOFiles(searchParams, gpsrSearchData)
      .then((response: AxiosResponse<Value>) => {
        commit('setTblUpdateGPSRProdVOFiles', response.data);
        commit('setTblUpdateGPSRProdVOFilesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTblUpdateGPSRProdVOFilesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getZRNummerList({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any, gpsrprodVOSearchData?: any  } ){
    let searchParams = payload?.searchParams ?? getters.getTblUpdateGPSRProdVOFilesSearchParams;
    let gpsrSearchData = payload?.gpsrprodVOSearchData || {};
      return defaultBackendTblUpdateGPSRProdVOFile
      .getZRNummerList(searchParams, gpsrSearchData)
      .then((response: AxiosResponse<ZRNummerListResult[]>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
    },
  getAddGPSRProdVOFileCompleted({ commit, dispatch }, payload: { id: number; zrMaster: string; contactEmail?: string }) {
    return defaultBackendTblUpdateGPSRProdVOFile
      .getAddGPSRProdVOFileCompleted(payload)
      .then(
        (
            response: AxiosResponse<{
              result: TblUpdateGPSRProdVOFile;
            }>
        ) => {
          return response.data.result;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCanselGPSRProdVOFileCompleted({ commit, dispatch }, payload: { id: number; zrMaster: string; contactEmail?: string }) {
    return defaultBackendTblUpdateGPSRProdVOFile
      .getCanselGPSRProdVOFileCompleted(payload)
      .then(
        (
          response: AxiosResponse<{
            result: TblUpdateGPSRProdVOFile;
          }>
        ) => {
          return response.data.result;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  downloadGPSRProdVOFile({ commit, dispatch }, data: any) {
    return defaultBackendTblUpdateGPSRProdVOFile
      .downloadGPSRProdVOFile(data)
      .then((response: any) => {
         if (response.headers['content-disposition']) {
            const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
            const type = response.headers['content-type'];
            console.log('type :>> ', type);
            const blob = new Blob([response.data], { type: type });
            console.log('blob :>> ', blob);
            const url = DownloadUtils.createDownloadLink(blob, fileName);
            console.log('url :>> ', url);
            window.URL.revokeObjectURL(url);
          }
          console.log('response.data :>> ', response.data);
          return response.data;
        }
      )
      .catch((e: any) => {
        logger.error(e);
        // module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  downloadArtusFiles({ commit, dispatch }, data: any) {
    
    return defaultBackendTblUpdateGPSRProdVOFile
      .downloadArtusFile(data)
      .then((response: any) => {
         if (response.headers['content-disposition']) {
            const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
            const type = response.headers['content-type'];
            console.log('type :>> ', type);
            const blob = new Blob([response.data], { type: type });
            console.log('blob :>> ', blob);
            const url = DownloadUtils.createDownloadLink(blob, fileName);
            console.log('url :>> ', url);
            window.URL.revokeObjectURL(url);
          }
          console.log('response.data :>> ', response.data);
          return response.data;
        }
      )
      .catch((e: any) => {
        logger.error(e);
        // module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDashboardDate({ commit, dispatch }, date: string) {
    commit('setDashboardDate', date);
  },
  updateDashboardGPSRProdVOFilesStatus({ commit, dispatch }, date: any) {
    commit('setDashboardGPSRProdVOFilesStatus', date);
  },
};
