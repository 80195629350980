import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import tblKollegenauslieferung, { TblKollegenauslieferung, Value } from '../model/tblKollegenauslieferung';
import {
  KollegenauslieferungFacetSearchData,
  KollegenauslieferungSearchData,
} from '@/shared/model/smallPayloadModels/kollegenauslieferungSearchData';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';
const searchableColumns: Map<string, string> = new Map([
  ['MemberzrNumber', 'zr_number'],
  ['GsName', 'gs_name'],
  ['ZipCircle', 'zip_circle'],
  ['DeliverAbroadCountry', 'deliver_abroad_country'],
  ['DeliverIslandName', 'deliver_island_name'],
  ['DeliveryColleagueContact', 'delivery_colleague_contact'],
  ['DeliveryColleagueEmail', 'delivery_colleague_email'],
  ['DeliveryColleaguePhone', 'delivery_colleague_phone'],
  ['DeliveryColleagueProductgroup', 'delivery_colleague_productgroup'],
  ['DeliveryColleagueCondition', 'delivery_colleague_condition'],
]);
const defaultOrSearchFields = [
  'zr_number',
  'gs_name',
  'zip_circle',
  "cast(deliver_abroad,'Edm.String')",
  'deliver_abroad_country',
  "cast(deliver_island,'Edm.String')",
  'deliver_island_name',
  "cast(delivery_colleague,'Edm.String')",
  'delivery_colleague_contact',
  'delivery_colleague_email',
  'delivery_colleague_phone',
  'delivery_colleague_productgroup',
  'delivery_colleague_condition',
];

export interface BackendTblKollegenauslieferung {
  getKollegenauslieferung: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  getKollegenauslieferungFacetSearch: (facetSearch: KollegenauslieferungFacetSearchData) => AxiosPromise<Value>;
  getZrNumberList(): AxiosPromise<any>;
  getVMEHauptwarengruppenList(): AxiosPromise<any>;
}

export const defaultBackendTblKollegenauslieferung: BackendTblKollegenauslieferung = {
  getZrNumberList(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.tblKollegenauslieferung}/GetZrNumberList`);
  },
  getVMEHauptwarengruppenList(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.tblKollegenauslieferung}/GetVMEHauptwarengruppenList`);
  },
  getKollegenauslieferung(searchParams: any, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    if (searchParams.andClauseFieldsIds?.length > 0) {
      if (!searchParams.andClauseFieldsIds?.some((x: any) => x.chipField == 'all')) {
        searchParams.orClauseFieldsIds = [];
      } else {
        let allChipField = searchParams.andClauseFieldsIds?.find((x: any) => x.chipField == 'all');
        searchParams.filter = allChipField.searchWord;
        searchParams.orClauseFieldsIds = defaultOrSearchFields;
      }
      let andClauseFieldsIds: Array<any> = searchParams.andClauseFieldsIds;

      andClauseFieldsIds.forEach((element: any, index: number) => {
        const chipField = searchableColumns.has(element.chipField)
          ? searchableColumns.get(element.chipField)
          : element.chipField;        
        if (element.chipField == 'all') {
          // do nothing
        } else if (chipField != 'zip_circle') {
          odfb.contains(`tolower(${chipField})`, element.searchWord.toLowerCase());
          //odfb.startsWith(`tolower(${chipField})`, element.searchWord.toLowerCase());
        }
        //  else {
        //   odfb.contains(`tolower(${chipField})`, element.searchWord.toLowerCase());
        // }
      });
    }
    
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.tblKollegenauslieferungOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    return instance.put<Value>(url, searchData);
  },
  getKollegenauslieferungFacetSearch(facetData: KollegenauslieferungFacetSearchData): AxiosPromise<any> {
    let url = `${URLS.tblKollegenauslieferung}/GetSearchResult`;
    return instance.put<Value>(url, facetData);
  },
};
