
import { SearchParamsEmpty } from '@/shared/model/searchParams';
import { TblUpdateScprixFilesState } from './types';

export const state = (): TblUpdateScprixFilesState => initialState();

export const initialState = (): TblUpdateScprixFilesState => ({
  dashboardDate: undefined,
  dashboardScprixFilesStatus: undefined,
  tblUpdateScprixFiles: {
    //TODO remove when was made backend
    items: [],
    isLoading: undefined,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['scp_filedate'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['scp_filename', 'scp_fileorder', 'scp_filedate', 'scp_importdate', 'scprixFileDoneTimeCompleted', 'scprixFileUserNameCompleted'],
      andClauseFieldsIds: [],
    },
  }, 
});
