
import { ActionTree } from 'vuex';
import { TblUpdateScprixFilesState } from './types';
import { RootState } from '../../types';
import { defaultBackendTblUpdateScprixFile } from '@/shared/backend/tblUpdateScprixFile';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import tblUpdateScprixFile, { Value, TblUpdateScprixFile } from '@/shared/model/tblUpdateScprixFile';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.tblUpdateDocument');
export const actions: ActionTree<TblUpdateScprixFilesState, RootState> = {
  getTblUpdateScprixFiles({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any, scprixSearchData?: any  }) {
    console.log('---------------------getTblUpdateScprixFiles---------------------------------------  ===>>>   ');
    commit('setTblUpdateScprixFilesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getTblUpdateScprixFilesSearchParams;
    let scprixSearchData = payload?.scprixSearchData || {};
    return defaultBackendTblUpdateScprixFile
      .getTblUpdateScprixFiles(searchParams, scprixSearchData)
      .then((response: AxiosResponse<Value>) => {
        commit('setTblUpdateScprixFiles', response.data);
        commit('setTblUpdateScprixFilesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTblUpdateScprixFilesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAddScprixFileCompleted({ commit, dispatch }, payload: { id: number; zrMaster: string; contactEmail?: string }) {
    console.log('-------action-----------------------getAddScprixFileCompleted------------------------------  ===>>>   ');
    return defaultBackendTblUpdateScprixFile
      .getAddScprixFileCompleted(payload)
      .then(
        (
            response: AxiosResponse<{
              result: TblUpdateScprixFile;
            }>
        ) => {
          return response.data.result;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCanselScprixFileCompleted({ commit, dispatch }, payload: { id: number; zrMaster: string; contactEmail?: string }) {
    return defaultBackendTblUpdateScprixFile
      .getCanselScprixFileCompleted(payload)
      .then(
        (
          response: AxiosResponse<{
            result: TblUpdateScprixFile;
          }>
        ) => {
          return response.data.result;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDashboardDate({ commit, dispatch }, date: string) {
    commit('setDashboardDate', date);
  },
  updateDashboardScprixFilesStatus({ commit, dispatch }, date: any) {
    commit('setDashboardScprixFilesStatus', date);
  },
};
