
import { ActionTree } from 'vuex';
import { TblUpdateConditionState } from './types';
import { RootState } from '../../types';
import { defaultBackendTblUpdateCondition } from '@/shared/backend/tblUpdateCondition';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import tblUpdateCondition, { Value, TblUpdateCondition } from '@/shared/model/tblUpdateCondition';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.tblUpdateCondition');
export const actions: ActionTree<TblUpdateConditionState, RootState> = {
  getTblUpdateConditions(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; conditionSearchData?: any }
  ) {
    commit('setTblUpdateConditionsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getTblUpdateConditionsSearchParams;
    let conditionSearchData = payload?.conditionSearchData || {};
    return defaultBackendTblUpdateCondition
      .getTblUpdateConditions(searchParams, conditionSearchData)
      .then((response: AxiosResponse<Value>) => {
        commit('setTblUpdateConditions', response.data);
        commit('setTblUpdateConditionsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTblUpdateConditionsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAddConditionCompleted({ commit, dispatch }, payload: { id: number; zrMaster: string; contactEmail?: string }) {
    return defaultBackendTblUpdateCondition
      .getAddConditionCompleted(payload)
      .then(
        (
          response: AxiosResponse<{
            result: TblUpdateCondition;
          }>
        ) => {
          return response.data.result;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCanselConditionCompleted({ commit, dispatch }, payload: { id: number; zrMaster: string; contactEmail?: string }) {
    return defaultBackendTblUpdateCondition
      .getCanselConditionCompleted(payload)
      .then(
        (
          response: AxiosResponse<{
            result: TblUpdateCondition;
          }>
        ) => {
          return response.data.result;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getTblUpdateSupplier({ commit, dispatch }, id: string) {
    return defaultBackendTblUpdateCondition
      .getTblUpdateSupplier(id)
      .then((response: AxiosResponse<TblUpdateCondition>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  }, 
  deleteTblUpdateCondition({ commit, dispatch }, id: string) {
    return defaultBackendTblUpdateCondition
      .deleteTblUpdateCondition(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TblUpdateCondition;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getConditionsVisible({ commit }, user_email: string) {
    return defaultBackendTblUpdateCondition
      .getConditionsVisible(user_email)
      .then((response: any) => {
        commit('setGetConditionsVisible', response.data);
        response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateDashboardDate({ commit, dispatch }, date: string) {
    commit('setDashboardDate', date);
  },
  updateDashboarCompanyWrGruppen({ commit, dispatch }, date: any) {
    commit('setDashboarCompanyWrGruppen', date);
  },
  updateDashboardConditionsStatus({ commit, dispatch }, date: any) {
    commit('setDashboardConditionsStatus', date);
  },
};
