import { MutationTree } from 'vuex';
import { TblUpdateScprixFilesState } from './types';
import Vue from 'vue';
import tblUpdateScprixFile, { TblUpdateScprixFile, Value } from '@/shared/model/tblUpdateScprixFile';

export const mutations: MutationTree<TblUpdateScprixFilesState> = {
  setTblUpdateScprixFiles(state, payload: Value) {
    state.tblUpdateScprixFiles.items = payload.value.map((x) => tblUpdateScprixFile.parse(x));
    state.tblUpdateScprixFiles.total = payload['@odata.count'] || 0;
    state.tblUpdateScprixFiles.isLoading = false;
  },
  setTblUpdateScprixFilesTotal(state, payload: number) {
    state.tblUpdateScprixFiles.total = payload;
  },
  setTblUpdateScprixFilesIsLoading(state, payload: boolean) {
    state.tblUpdateScprixFiles.isLoading = payload;
  },
  setDashboardDate(state, payload: string) {
    state.dashboardDate = payload;
  },
  setDashboardScprixFilesStatus(state, payload: string) {
    state.dashboardScprixFilesStatus = payload;
  },
};
