import { GetterTree } from 'vuex';
import { TblMitgliederState } from './types';
import { RootState } from '../../types';
import { MitgliederSearchData } from '@/shared/model/smallPayloadModels/mitgliederSearchData';

export const getters: GetterTree<TblMitgliederState, RootState> = {
  ['getMitglieders']: (state) => state.tblMitglieders,
  ['getMitgliedersIsLoading']: (state) => state.tblMitglieders?.isLoading,
  ['getMitgliedersTotal']: (state) => state.tblMitglieders?.total,
  ['getMitgliedersSearchParams']: (state) => state.tblMitglieders?.searchParams,
  ['getMitgliederSearchData']: (state) => state.mitgliederSearchData,

  ['isMitgliederSearchDataEmpty']: (state) => {
    const searchData: MitgliederSearchData = state.mitgliederSearchData;
    let hasZrNummer = searchData.zrNumbersId?.length > 0;
    let hasStatusCompanies = searchData.statusCompanies?.length > 0;
    let isEmpty = !hasZrNummer && !hasStatusCompanies;
    return isEmpty;
  },
};
