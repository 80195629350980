import { SearchParamsEmpty } from '@/shared/model/searchParams';
import { TblUpdateGPSRProdVOFilesState } from './types';

export const state = (): TblUpdateGPSRProdVOFilesState => initialState();

export const initialState = (): TblUpdateGPSRProdVOFilesState => ({
  dashboardDate: undefined,
  dashboardGPSRProdVOFilesStatus: undefined,
  tblUpdateGPSRProdVOFiles: {
    //TODO remove when was made backend
    items: [],
    isLoading: undefined,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['gpsr_filedate'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['gpsr_zrnummer', 'gpsr_filename', 'gpsr_filedate', 'gpsrFileDoneTimeCompleted', 'gpsrFileUserNameCompleted'],
      andClauseFieldsIds: [],
    },
  },
});
