import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import mitgliederSearchData, {
  MitgliederFacetSearchData,
  MitgliederSearchData,
} from '@/shared/model/smallPayloadModels/mitgliederSearchData';
import DateField from '@/components/_common/date-field/DateField.vue';
import DateUtils from '@/shared/utils/DateUtils';

const logger = new Logger('search-form');
const tblMitgliederModule = namespace('tblMitglieder');

@Component({
  components: { DateField },
})
export default class SearchFormKollegenauslieferung extends Vue {
  @Ref('deliverAbroadCombobx')
  private refDeliverAbroadCombobx!: any;
  @Ref('statusesCombobox')
  private refStatusesCombobox!: any;
  @Ref('membersCombobox')
  private refMembersCombobox!: any;

  @Ref('titlesCombobox')
  private refTitlesCombobox!: any;

  @tblMitgliederModule.Action('getZrNumberList')
  private actionGetZrNumberList!: any;

  @tblMitgliederModule.Action('getStatusCompaniesList')
  private actionGetStatusCompaniesList!: any;

  @tblMitgliederModule.Action('updateMitgliederSearchData')
  private actionUpdateMitgliederSearchData!: any;

  @tblMitgliederModule.Getter('getMitgliedersSearchParams')
  private getMitgliedersSearchParams!: any;

  @tblMitgliederModule.Getter('getMitgliederSearchData')
  private getMitgliederSearchData!: any;

  @tblMitgliederModule.Getter('isMitgliederSearchDataEmpty')
  private isMitgliederSearchDataEmpty!: any;

  listZrNumber = [];
  listStatusCompanies = [];
  private selectedStatus: string = '';
  mitgliederSearchData = mitgliederSearchData.defaultData();

  created() {
    if (!this.getMitgliedersSearchParams) {
      this.getMitgliedersSearchParams = mitgliederSearchData.defaultData();
    }
    this.mitgliederSearchData = this.getMitgliederSearchData;
  }

  async mounted() {
    var promiseAll = [];
    promiseAll.push(this.getZrNumberList(), this.getStatusCompaniesList());
  }

  isSearchDataWasCleared = false;
  @Watch('getMitgliedersIsLoading', { deep: true })
  public async onIsLieferantenSearchDataEmpty(newVal: boolean, oldVal: boolean) {
    if (newVal == true) {
      this.isSearchDataWasCleared = true; // avoid eternal loop on clearing `searchData` (watcher glitch without this variable)
      this.mitgliederSearchData = mitgliederSearchData.defaultData();
    }
  }

  @Watch('mitgliederSearchData', { deep: true })
  public async onOptionsFilterChanged(
    newVal: MitgliederSearchData,
    oldVal: MitgliederSearchData
  ) {
    this.hideSubTanle();
    // avoid loop when `searchData` was reset
    if (!this.isSearchDataWasCleared) {
      let payload = Object.assign({}, this.getMitgliederSearchData);
      payload.zrNumbersId = this.mitgliederSearchData.zrNumbersId;
      payload.statusCompanies = this.mitgliederSearchData.statusCompanies;
     
      this.actionUpdateMitgliederSearchData(payload);
    }
    this.isSearchDataWasCleared = false;
  }

  private loadingMembers = false;
  private async getZrNumberList() {
    this.actionGetZrNumberList()
      .then((result: any) => {
        this.listZrNumber = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async getStatusCompaniesList() {
    this.actionGetStatusCompaniesList()
      .then((result: any) => {
        this.listStatusCompanies = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  showMenu() {
    this.refDeliverAbroadCombobx.activateMenu();
  }

  reloadRechnungsnummersList: boolean = false;
  reloadStatusesList: boolean = false;
  reloadMembersList: boolean = false;
  reloadSuppliersList: boolean = false;

  //#endregion

  searchRechnungsnummerInput = '';
  searchStatusInput = '';
  searchMembersInput = '';
  searchTitlesInput = '';
  searchModelNameInput = '';
  searchSupplierInput = '';

  clearSearchInput() {
    this.searchRechnungsnummerInput = '';
    this.searchStatusInput = '';
    this.searchMembersInput = '';
    this.searchTitlesInput = '';
    this.searchModelNameInput = '';
    this.searchSupplierInput = '';
  }

  // clearSearchFormData() {
  //   this.actionResetwochenabschluesseSearchFormData();
  // }

  private allowedDates(date: string) {
    const dateValue = new Date(date);
    let isFriday = dateValue.getDay() === 5;
    const today = new Date();
    let twoWeeksFromToday = new Date(today.getTime() + 12096e5 - 864e5); // `12096e5` is 14 days in milliseconds // (GSP-098) use 13 days (not 14) for 2 weeks after to be able to select again the Friday that 2 weeks from current Friday (otherwise it will be selected by default but you could not select it again manually)
    //`864e5` = 86400000 is 1 day in milliseconds

    if (this.isTodayMondayOrTuesday()) {
      twoWeeksFromToday = new Date(today.getTime() + 6048e5); // add 7 days in milliseconds to make next Friday valid (in 11 or 10 days (from Mon and Tue), not in 18 and 17 days as was before)
    }

    const isDateMoreThan2Weeks = dateValue > twoWeeksFromToday;

    return isFriday; // && isDateMoreThan2Weeks;
  }

  private isTodayMondayOrTuesday() {
    const today = new Date();
    const isMondayOrTuesday = today.getDay() === 1 || today.getDay() == 2;
    return isMondayOrTuesday;
  } 

  private hideSubTanle() {
    let expandedLists = document.querySelectorAll<HTMLElement>('.mdi-chevron-down.mdi-flip-v');
    expandedLists.forEach((element) => {
      element.click();
    });
  }
  
}
