
import { MutationTree } from 'vuex';
import { TblMitgliederState } from './types';
import Vue from 'vue';
import tblMitglieder, { TblMitglieder, Value } from '@/shared/model/tblMitglieder';

export const mutations: MutationTree<TblMitgliederState> = {
  setTblMitglieders(state, payload: Value) {
    state.tblMitglieders.items = payload.value.map((x) => tblMitglieder.parse(x));
    state.tblMitglieders.total = payload['@odata.count'] || 0;
    state.tblMitglieders.isLoading = false;
  },
  setTblMitgliederTotal(state, payload: number) {
    state.tblMitglieders.total = payload;
  },
  setTblMitgliederIsLoading(state, payload: boolean) {
    state.tblMitglieders.isLoading = payload;
  }, 
  setMitgliederSearchData(state, payload: any) {
    state.mitgliederSearchData = payload;
  },
};
