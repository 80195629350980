import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import tblUpdateScprixFile, { TblUpdateScprixFile, Value } from '../model/tblUpdateScprixFile';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.TblUpdateSupplier');
export interface BackendTblUpdateScprixFile {  
  getTblUpdateScprixFiles: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;  
  getAddScprixFileCompleted(payload: any): AxiosPromise<any>;  
  getCanselScprixFileCompleted(payload: any): AxiosPromise<any>;

  downloadScripxFile(data: any): AxiosPromise<any>;
}

export const defaultBackendTblUpdateScprixFile: BackendTblUpdateScprixFile = {
  getTblUpdateScprixFiles(searchParams: SearchParams, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.tblUpdateScprixFileOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );   
    if (searchParams.filter?.length != 0)
    {
        url=url.replace("contains(tolower(scp_filedate),","contains(tolower(cast(scp_filedate, 'Edm.String')),");
        url=url.replace("contains(tolower(scp_importdate),","contains(tolower(cast(scp_importdate, 'Edm.String')),");
        url=url.replace("contains(tolower(scprixFileDoneTimeCompleted),","contains(tolower(cast(scprixFileDoneTimeCompleted, 'Edm.String')),");
    }

    return instance.put<Value>(url, searchData);
  }, 
  getAddScprixFileCompleted( data: any): AxiosPromise<any> {
    return instance.put(`${URLS.tblUpdateScprixFile}/AddScprixFileCompleted`, data);
  },
  getCanselScprixFileCompleted( data: any): AxiosPromise<any> {
    return instance.put(`${URLS.tblUpdateScprixFile}/CanselScprixFileCompleted`, data);
  }, 

  downloadScripxFile(data: any): AxiosPromise<any> {
    logger.debug(`downloadScripxFile: ${data}`);
    return instance.put<any>(`${URLS.tblUpdateScprixFile}/DownloadFile`, data, {
      responseType: 'blob',
    });
  },
};
