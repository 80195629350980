export interface TblUpdateGPSRProdVOFile {
  id: number; // uint
  gpsr_zrnummer: string; // string
  gpsr_filename: string; // string  
  gpsr_filedate: string; // string
  doc_id: string | null; // string
  gpsrFileDoneTimeCompleted: string; // string
  gpsrFileUserNameCompleted: string; // string

  isDownloading: boolean; // pure frontend field
}

export interface Value {
  value: TblUpdateGPSRProdVOFile[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

export interface ZRNummerListResult {
  zrNummer: string;
}

// init api object
function toAPI(data: Partial<TblUpdateGPSRProdVOFile>): any {
  return {
    id: data?.id || 0,
    gpsr_zrnummer: data?.gpsr_zrnummer || '', 
    gpsr_filename: data?.gpsr_filename || '',   
    gpsr_filedate: data?.gpsr_filedate || '',
    doc_id: data?.doc_id || null,
    gpsrFileDoneTimeCompleted: data?.gpsrFileDoneTimeCompleted || '',
    gpsrFileUserNameCompleted: data?.gpsrFileUserNameCompleted || '',
  };
}

function parse(data?: Partial<TblUpdateGPSRProdVOFile>): TblUpdateGPSRProdVOFile {
  return {
    id: data?.id || 0,
    gpsr_zrnummer: data?.gpsr_zrnummer || '',
    gpsr_filename: data?.gpsr_filename || '',
    gpsr_filedate: data?.gpsr_filedate || '',
    doc_id: data?.doc_id || null,
    gpsrFileDoneTimeCompleted: data?.gpsrFileDoneTimeCompleted || '',
    gpsrFileUserNameCompleted: data?.gpsrFileUserNameCompleted || '',

    isDownloading: data?.isDownloading || false,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
