export interface TblLieferanten {
  id: number; // uint
  name: string; // string
  status: string; // string
  unternehmensnameZUSATZ: string; // string
  suchname2: string; // bool
  zrBemerkungenzuKreditor_EXTERN: string; // string
  zrNummer: string; // bool
  konditionen: string; // string
  strasse: string; // bool
  plz: string; // string
  ort: string; // string
  land: string; // string
  telefon: string; // string
  eMailAdresse: string; // string
  uStIdNrn: string; // string
  zrBeginn: string; // string
  zrEnde: string; // string
  plzGebiet	: string; // string
  webseite: string; // string
  hBID: string; // string
  vMEZugehoerigkeit: string; // string
}

export interface Value {
  value: TblLieferanten[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<TblLieferanten>): any {
  return {
    id: data?.id || 0,
    name: data?.name || '',
    status: data?.status || '',
    unternehmensnameZUSATZ: data?.unternehmensnameZUSATZ || '',
    suchname2: data?.suchname2 || '',
    zrBemerkungenzuKreditor_EXTERN: data?.zrBemerkungenzuKreditor_EXTERN || '',
    zrNummer: data?.zrNummer || '',
    konditionen: data?.konditionen || '',
    strasse: data?.strasse || '',
    plz: data?.plz || '',
    ort: data?.ort || '',
    land: data?.land || '',
    telefon: data?.telefon || '',
    eMailAdresse: data?.eMailAdresse || '',
    uStIdNrn: data?.uStIdNrn || '',
    zrBeginn: data?.zrBeginn || '',
    zrEnde: data?.zrEnde || '',
    plzGebiet	: data?.plzGebiet	 || '',
    webseite: data?.webseite || '',
    hBID: data?.hBID || '',
    vMEZugehoerigkeit: data?.vMEZugehoerigkeit || '',
  };
}

function parse(data?: Partial<TblLieferanten>): TblLieferanten {
  return {
    id: data?.id || 0,
    name: data?.name || '',
    status: data?.status || '',
    unternehmensnameZUSATZ: data?.unternehmensnameZUSATZ || '',
    suchname2: data?.suchname2 || '',
    zrBemerkungenzuKreditor_EXTERN: data?.zrBemerkungenzuKreditor_EXTERN || '',
    zrNummer: data?.zrNummer || '',
    konditionen: data?.konditionen || '',
    strasse: data?.strasse || '',
    plz: data?.plz || '',
    ort: data?.ort || '',
    land: data?.land || '',
    telefon: data?.telefon || '',
    eMailAdresse: data?.eMailAdresse || '',
    uStIdNrn: data?.uStIdNrn || '',
    zrBeginn: data?.zrBeginn || '',
    zrEnde: data?.zrEnde || '',
    plzGebiet	: data?.plzGebiet	 || '',
    webseite: data?.webseite || '',
    hBID: data?.hBID || '',
    vMEZugehoerigkeit: data?.vMEZugehoerigkeit || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
