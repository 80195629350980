import { GetterTree } from 'vuex';
import { TblUpdateGPSRProdVOFilesState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<TblUpdateGPSRProdVOFilesState, RootState> = {
  ['getTblUpdateGPSRProdVOFiles']: (state) => state.tblUpdateGPSRProdVOFiles,
  ['getTblUpdateGPSRProdVOFilesIsLoading']: (state) => state.tblUpdateGPSRProdVOFiles?.isLoading,
  ['getTblUpdateGPSRProdVOFilesTotal']: (state) => state.tblUpdateGPSRProdVOFiles?.total,
  ['getTblUpdateGPSRProdVOFilesSearchParams']: (state) => state.tblUpdateGPSRProdVOFiles?.searchParams,
  ['getDashboardDate']: (state) => state.dashboardDate,
  ['getDashboadrdGPSRProdVOFilesStatus']: (state) => state.dashboardGPSRProdVOFilesStatus,
};
