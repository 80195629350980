export interface TblUpdateNews {
    id: number; // uint  
    nw_image: string; // string  
    nw_title: string; // string  
    nw_description: string; // string  
    nw_sitetitle: string; // string  
    nw_category: string; // string  
    nw_author: string; // string  
    nw_createdate: string; // System.DateTime  
    nw_pfad: string; // string 
    sp_isdeleted: boolean; // bool  
    nw_countviews: number; // number  
    newsDoneTimeCompleted: string; // string 
    newsUserNameCompleted: string; // string    
}

export interface CompletedInfo {
    completedDoneTime: string; // string 
    completedUserName: string; // string  ;
}
export interface Value {
    value: TblUpdateNews[];
    '@odata.context'?: string | undefined;
    '@odata.count'?: number | undefined;
}

export interface BereichListResult {
    bereich: string;   
  }

  export interface AuthorListResult {
    firstname: string;   
    lastname: string; 
  }

  export interface CategoryListResult {
    category: string;   
  }

// init api object
function toAPI(data: Partial<TblUpdateNews>): any {
    return {
        id: data?.id || 0,
        nw_image: data?.nw_image || '',
        nw_title: data?.nw_title || '',
        nw_description: data?.nw_description || '',
        nw_sitetitle: data?.nw_sitetitle || '',
        nw_category: data?.nw_category || '',
        nw_author: data?.nw_author || '',
        nw_pfad: data?.nw_pfad || '',
        nw_createdate: data?.nw_createdate || '',
        sp_isdeleted: data?.sp_isdeleted || false,
        nw_countviews:data?.nw_countviews || 0,
        newsDoneTimeCompleted: data?.newsDoneTimeCompleted || '',
        newsUserNameCompleted: data?.newsUserNameCompleted || '',
    };
}

function parse(data?: Partial<TblUpdateNews>): TblUpdateNews {
    return {
        id: data?.id || 0,
        nw_image: data?.nw_image || '',
        nw_title: data?.nw_title || '',
        nw_description: data?.nw_description || '',
        nw_sitetitle: data?.nw_sitetitle || '',
        nw_category: data?.nw_category || '',
        nw_author: data?.nw_author || '',
        nw_pfad: data?.nw_pfad || '',
        nw_createdate: data?.nw_createdate || '',
        sp_isdeleted: data?.sp_isdeleted || false,
        nw_countviews:data?.nw_countviews || 0,
        newsDoneTimeCompleted: data?.newsDoneTimeCompleted || '',
        newsUserNameCompleted: data?.newsUserNameCompleted || '',
    };
}

export default {
    parse,
    toAPI: toAPI,
};
