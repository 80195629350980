export interface TblUpdateScprixFile {
  id: number; // uint
  scp_filename: string; // string
  scp_fileorder: string; // string
  scp_filedate: string; // string
  scp_importdate: string; // string
  cnd_isdeleted: boolean; // bool
  scprixFileDoneTimeCompleted: string; // string
  scprixFileUserNameCompleted: string; // string
}

export interface Value {
  value: TblUpdateScprixFile[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<TblUpdateScprixFile>): any {
  return {
    id: data?.id || 0,
    scp_filename: data?.scp_filename || '',
    scp_fileorder: data?.scp_fileorder || '',
    scp_filedate: data?.scp_filedate || '',
    scp_importdate: data?.scp_importdate || '',
    cnd_isdeleted: data?.cnd_isdeleted || false,
    scprixFileDoneTimeCompleted: data?.scprixFileDoneTimeCompleted || '',
    scprixFileUserNameCompleted: data?.scprixFileUserNameCompleted || '',
  };
}

function parse(data?: Partial<TblUpdateScprixFile>): TblUpdateScprixFile {
  return {
    id: data?.id || 0,
    scp_filename: data?.scp_filename || '',
    scp_fileorder: data?.scp_fileorder || '',
    scp_filedate: data?.scp_filedate || '',
    scp_importdate: data?.scp_importdate || '',
    cnd_isdeleted: data?.cnd_isdeleted || false,
    scprixFileDoneTimeCompleted: data?.scprixFileDoneTimeCompleted || '',
    scprixFileUserNameCompleted: data?.scprixFileUserNameCompleted || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
