import { ActionTree } from 'vuex';
import { TblMitgliederState } from './types';
import { RootState } from '../../types';
import { defaultBackendTblMitglieder } from '@/shared/backend/tblMitglieder';
import {
  MitgliederFacetSearchData,
  MitgliederSearchData,
} from '@/shared/model/smallPayloadModels/mitgliederSearchData';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import tblMitglieder, { Value, TblMitglieder } from '@/shared/model/tblMitglieder';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

export const actions: ActionTree<TblMitgliederState, RootState> = {
  getMitglieder(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; mitgliederSearchData?: any }
  ) {
    commit('setTblMitgliederIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getMitgliederSearchData;
    let searchData = payload?.mitgliederSearchData ?? {};
    return defaultBackendTblMitglieder
      .getMitglieder(searchParams, searchData)
      .then((response: AxiosResponse<Value>) => {
        commit('setTblMitglieders', response.data);
        commit('setTblMitgliederIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTblMitgliederIsLoading', false);
        throw e;
      });
  },
  getZrNumberList({ commit, dispatch }) {
    return defaultBackendTblMitglieder
      .getZrNumberList()
      .then((response: AxiosResponse<any>) => {
        return response.data;
      })
      .catch((e: any) => {
        throw e;
      });
  },
  getStatusCompaniesList({ commit, dispatch }) {
    return defaultBackendTblMitglieder
      .getStatusCompaniesList()
      .then((response: AxiosResponse<any>) => {
        return response.data;
      })
      .catch((e: any) => {
        throw e;
      });
  },
  getMitgliederFacetSearch({ commit, dispatch }, payload: MitgliederFacetSearchData) {
    return defaultBackendTblMitglieder
      .getMitgliederFacetSearch(payload)
      .then((response: AxiosResponse<any>) => {
        const result = response.data.$values ? response.data.$values : response.data;
        return result;
      })
      .catch((e: any) => {
        throw e;
      });
  },
  getMitgliederGebietPLZ(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; mitgliederSearchData?: any }
  ) {
    commit('setTblMitgliederIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getMitgliederSearchData;
    let searchData = payload?.mitgliederSearchData ?? {};
    return defaultBackendTblMitglieder
      .getMitgliederGebietPLZ(searchParams, searchData)
      .then((response: AxiosResponse<Value>) => {
        commit('setTblMitgliederIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTblMitgliederIsLoading', false);
        throw e;
      });
  },
  updateMitgliederSearchData({ commit, dispatch }, data: any) {
    commit('setMitgliederSearchData', data);
  },
};
