import { MutationTree } from 'vuex';
import { TblUpdateGPSRProdVOFilesState } from './types';
import Vue from 'vue';
import tblUpdateGPSRProdVOFile, { TblUpdateGPSRProdVOFile, Value } from '@/shared/model/tblUpdateGPSRProdVOFile';

export const mutations: MutationTree<TblUpdateGPSRProdVOFilesState> = {
  setTblUpdateGPSRProdVOFiles(state, payload: Value) {
    state.tblUpdateGPSRProdVOFiles.items = payload.value.map((x) => tblUpdateGPSRProdVOFile.parse(x));
    state.tblUpdateGPSRProdVOFiles.total = payload['@odata.count'] || 0;
    state.tblUpdateGPSRProdVOFiles.isLoading = false;
  },
  setTblUpdateGPSRProdVOFilesTotal(state, payload: number) {
    state.tblUpdateGPSRProdVOFiles.total = payload;
  },
  setTblUpdateGPSRProdVOFilesIsLoading(state, payload: boolean) {
    state.tblUpdateGPSRProdVOFiles.isLoading = payload;
  },
  setDashboardDate(state, payload: string) {
    state.dashboardDate = payload;
  },
  setDashboardGPSRProdVOFilesStatus(state, payload: string) {
    state.dashboardGPSRProdVOFilesStatus = payload;
  },
};
