export interface MitgliederFacetSearchData extends MitgliederSearchData {
  searchWord: string;
  chipDataString: string;
  // need all fields from SearchData for proper filtering and correct count numbers
}

export interface MitgliederSearchData {
  zrNumbersId: string[];
  statusCompanies: string[];
  viewTable: number;
  gebietPLZ: string;
  searchFacetFields: Array<any>;
  chipDataString?: string;
}

function defaultData(): MitgliederSearchData {
  return {
    zrNumbersId: [],
    statusCompanies: [],
    viewTable: 0,
    gebietPLZ: '',
    searchFacetFields: [],
  };
}

export default {
  defaultData,
};
